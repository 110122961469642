import {
  __publicField
} from "./chunk-KMAXXKJT.js";

// src/containers/Homepage/HomeUtils/HomeUtils.js
import { DAM_ASSETS_FIELD_KEY } from "aesirx-lib";
var HomeUtils = class {
  constructor() {
    __publicField(this, "transformPersonaResponseIntoModel", (response) => {
      return response;
    });
    __publicField(this, "checkFileTypeFormData", (data) => {
      switch (data[DAM_ASSETS_FIELD_KEY.FILE_EXTENTION]) {
        case "xlsx":
          return "/assets/images/xlsx.svg";
        case "docx":
          return "/assets/images/word.svg";
        case "pptx":
          return "/assets/images/pptx.svg";
        case "pdf":
          return "/assets/images/pdf.svg";
        default:
          return "/assets/images/file_default.svg";
      }
    });
    __publicField(this, "convertImageEditortoFile", (damEditdata, formPropsData, editorRef) => {
      if (damEditdata?.[DAM_ASSETS_FIELD_KEY.TYPE] === "image") {
        const editorInstance = editorRef.current.getInstance();
        formPropsData[DAM_ASSETS_FIELD_KEY.FILE] = this.dataURLtoFile(
          editorInstance.toDataURL({ format: damEditdata?.[DAM_ASSETS_FIELD_KEY.FILE_EXTENTION] }),
          damEditdata?.[DAM_ASSETS_FIELD_KEY.NAME]
        );
      }
      return formPropsData;
    });
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[arr.length - 1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
};
var utils = new HomeUtils();
var HomeUtils_default = utils;

// src/svg/Folder.jsx
import React from "react";
var Folder = ({ fill = "none", ...props }) => {
  return /* @__PURE__ */ React.createElement(
    "svg",
    {
      ...props,
      width: "46",
      height: "37",
      viewBox: "0 0 46 37",
      fill,
      xmlns: "http://www.w3.org/2000/svg"
    },
    /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M45.5 5.61439V10.8644C45.4992 9.93903 45.1313 9.0518 44.4769 8.39747C43.8226 7.74314 42.9354 7.37519 42.01 7.37439H28.39C27.8242 7.37419 27.27 7.21399 26.7914 6.91231C26.3128 6.61062 25.9292 6.17974 25.685 5.66939L23.815 1.77939C23.771 1.68458 23.7209 1.59272 23.665 1.50439H41.39C42.48 1.50439 43.5254 1.93741 44.2962 2.70819C45.067 3.47896 45.5 4.52435 45.5 5.61439Z",
        fill: "#1058E8"
      }
    ),
    /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M45.5 10.8645V33.4295C45.5003 34.3556 45.1328 35.244 44.4784 35.8993C43.824 36.5547 42.9361 36.9234 42.01 36.9245H3.99C3.06385 36.9234 2.17602 36.5547 1.5216 35.8993C0.867185 35.244 0.499734 34.3556 0.5 33.4295V3.56946C0.499734 2.64332 0.867185 1.75495 1.5216 1.0996C2.17602 0.444245 3.06385 0.0755231 3.99 0.0744629H21.11C21.6226 0.0746994 22.1265 0.20626 22.5738 0.456595C23.0211 0.706931 23.3968 1.06769 23.665 1.50446C23.7209 1.59278 23.771 1.68464 23.815 1.77946L25.685 5.66946C25.9292 6.17981 26.3128 6.61069 26.7914 6.91238C27.27 7.21406 27.8242 7.37426 28.39 7.37446H42.01C42.9354 7.37526 43.8226 7.74321 44.4769 8.39754C45.1313 9.05187 45.4992 9.9391 45.5 10.8645Z",
        fill: "#3F8EFC"
      }
    )
  );
};
var Folder_default = Folder;

export {
  HomeUtils_default,
  Folder_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
